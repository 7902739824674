import { SyntheticEvent, useCallback, useContext, useEffect, useState } from 'react';
import { Context } from '../utils/context';
import { useNavigate, useParams } from 'react-router-dom';
import { useAPI } from '../hooks/UseAPI';
import Loading from '../components/Loading';
import { useCurrencyByCode } from '../hooks/CurrencyFromCode';
import Select from '../components/Select';
import { processDecimalInput } from '../utils/process-decimal-input';
import { defaultFinancialElement } from '../model/FinancialElement';
import { Valuation, defaultValuation } from '../model/Valuation';
import { sortElements } from '../utils/sort-elements';
import { useVerticalScrollWithShadow } from '../hooks/VerticalScrollWithShadow';
import EmptyList from '../components/EmptyList';
import { FinancialElementTypes } from '../consts/FinancialElementTypes';
import { getFormattedLocaleDate, getHourInMilis, ifDateIsValidThen } from '../utils/date-utils';
import OperationsAndValuations from '../components/OperationsAndValuations';
import { getInteger, getDecimal } from '../utils/number-utils';

export default function DebtFull(): JSX.Element {
    const { client, financialElements, setFinancialElements, setConfirmDialog } = useContext(Context);
    const { createFinancialElement, createValuation, deleteValuation, getFinancialElement, listValuations, updateFinancialElement, updateValuation } = useAPI();
    const [loading, setLoading] = useState(true);
    const { debt_id } = useParams();
    const [financialElement, setFinancialElement] = useState({ ...defaultFinancialElement });
    const [valuations, setValuations] = useState<Valuation[]>([]);
    const { ref, boxShadow, onScrollHandler } = useVerticalScrollWithShadow();
    const navigate = useNavigate();
    const { getSign } = useCurrencyByCode();
    const [newValuation, setNewValuation] = useState({ ...defaultValuation });
    const [displayNewValuation, setDisplayNewValuation] = useState(false);
    const [selectedNewType, setSelectedNewType] = useState(FinancialElementTypes.DEBT);
    const [valuationToUpdate, setValuationToUpdate] = useState({ ...defaultValuation });
    const [displayValuationUpdate, setDisplayValuationUpdate] = useState(false);
    const [selectedUpdateType, setSelectedUpdateType] = useState(FinancialElementTypes.DEBT);
    const [deferEnable, setDeferEnable] = useState(false);
    const [date, setDate] = useState<Date>();
    const [createDeferAccount, setCreateDeferAccount] = useState<boolean>(true);
    const [currentlyHasDeferAccount, setCurrentlyHasDeferAccount] = useState<boolean>(true);

    const setUp = useCallback(async () => {
        setLoading(true);
        try {
            if (!debt_id) {
                navigate('/debts');
                return;
            }
            const now = new Date();
            setDate(now);
            const financialElement = await getFinancialElement(client.id ?? '', debt_id);
            setFinancialElement({ ...defaultFinancialElement, ...financialElement });
            setDeferEnable((financialElement.deferred ?? '') !== '');
            setCurrentlyHasDeferAccount((financialElement.deferred ?? '') !== '');
            const valuations = await listValuations(client.id ?? '', {
                financial_element_id: financialElement.id
            });
            sortElements(valuations, [
                { field: 'timestamp', asc: false },
                { field: 'hour', asc: false },
                { field: 'update_timestamp', asc: false },
            ]);
            setValuations(valuations);
            setNewValuation({
                ...defaultValuation,
                timestamp: getFormattedLocaleDate(now),
                currency_code: valuations[0]?.currency_code ?? (client.currencies ? client.currencies[0]?.code ?? '' : '')
            });
            setDisplayNewValuation(false);
            setDisplayValuationUpdate(false);
        } finally {
            setLoading(false);
        }
    }, [debt_id, navigate, client.id, client.currencies, getFinancialElement, listValuations]);

    useEffect(() => { setUp() }, [setUp]);

    function handleFinancialElementInputChange(event: SyntheticEvent) {
        const target = event.target as HTMLInputElement;
        setFinancialElement(oldFinancialElement => ({
            ...oldFinancialElement,
            [target.name]: target.value
        }));
    }

    function handleNewValuationInputChange(event: SyntheticEvent) {
        const target = event.target as HTMLInputElement;
        setNewValuation(oldValuation => ({
            ...oldValuation,
            [target.name]: target.value
        }));
    }

    async function saveUpdateFinancialElementChanges() {
        setLoading(true);
        try {
            let newFinancialElement = financialElement;
            if (deferEnable) {
                if (createDeferAccount && !currentlyHasDeferAccount) {
                    const deferNewAccount = await createFinancialElement(client.id ?? '', {
                        ...defaultFinancialElement,
                        type: 'DEBT',
                        status: 'ENABLED',
                        title: `${financialElement.title} (cuotas pendientes)`
                    });
                    setFinancialElements(oldFinancialElements => [deferNewAccount, ...oldFinancialElements])
                    newFinancialElement = {
                        ...financialElement,
                        deferred: deferNewAccount.id
                    };
                }
            } else {
                newFinancialElement = {
                    ...financialElement,
                    deferred: ''
                };
            }
            await updateFinancialElement(client.id ?? '', newFinancialElement.id ?? '', newFinancialElement);
            setFinancialElements(oldFinancialElements => [newFinancialElement, ...oldFinancialElements.filter(oldFinancialElement => oldFinancialElement.id !== financialElement.id)]);
        } finally {
            setUp();
        }
    }

    async function saveCreateNewValuation() {
        setLoading(true);
        try {
            await createValuation(client.id ?? '', {
                ...newValuation,
                financial_element_id: financialElement.id,
                amount: selectedNewType === FinancialElementTypes.DEBT ? `-${newValuation.amount}` : newValuation.amount,
                timestamp: new Date(newValuation.timestamp ?? '').getTime().toString(),
                hour: getHourInMilis(date).toString(),
            });
        } finally {
            setUp();
        }
    }

    function handleEditValuation(valuation: Valuation) {
        setValuationToUpdate({
            ...valuation,
            timestamp: new Date(parseInt(valuation.timestamp ?? '')).toISOString().substring(0, 10),
            amount: valuation.amount?.replace('-', '')
        });
        setDisplayValuationUpdate(true);
        setSelectedUpdateType(valuation.amount?.includes('-') ? FinancialElementTypes.DEBT : FinancialElementTypes.ASSET);
        setDisplayNewValuation(false);
    }

    function handleValuationToUpdateInputChange(event: SyntheticEvent) {
        const target = event.target as HTMLInputElement;
        setValuationToUpdate(oldValuation => ({
            ...oldValuation,
            [target.name]: target.value
        }));
    }

    async function saveUpdateValuation() {
        setLoading(true);
        try {
            await updateValuation(client.id ?? '', valuationToUpdate.id ?? '', {
                ...valuationToUpdate,
                amount: selectedUpdateType === FinancialElementTypes.DEBT ? `-${valuationToUpdate.amount}` : valuationToUpdate.amount,
                timestamp: new Date(valuationToUpdate.timestamp ?? '').getTime().toString()
            });
        } finally {
            setUp();
        }
    }

    async function confirmFinancialElementUpdate(status: string) {
        setLoading(true);
        try {
            await updateFinancialElement(client.id ?? '', financialElement.id ?? '', { ...financialElement, status });
        } finally {
            setUp();
        }
    }

    async function confirmDeleteValuation() {
        setLoading(true);
        try {
            await deleteValuation(client.id ?? '', valuationToUpdate.id ?? '');
        } finally {
            setUp();
        }
    }

    const confirmDisableDialogParams = {
        callback: () => confirmFinancialElementUpdate('DISABLED'),
        title: '¿Estás seguro que deseas deshabilitar esta deuda?',
        confirmButton: 'Deshabilitar',
        cancelButton: 'Cancelar'
    };

    const confirmEnableDialogParams = {
        callback: () => confirmFinancialElementUpdate('ENABLED'),
        title: '¿Estás seguro que deseas habilitar esta deuda?',
        confirmButton: 'Habilitar',
        cancelButton: 'Cancelar'
    };

    const confirmDeleteValuationDialogParams = {
        callback: confirmDeleteValuation,
        title: '¿Estás seguro que deseas eliminar este registro de saldo?',
        confirmButton: 'Eliminar',
        cancelButton: 'Cancelar'
    };

    if (loading) return <Loading />

    const updateCurrencyCodes = [valuationToUpdate.currency_code].concat(client.currencies?.map(currency => currency.code).filter(currencyCode => currencyCode !== valuationToUpdate.currency_code));

    const financialElementsOptions = financialElements
        .filter(fe => fe.status === 'ENABLED' && !fe.transactional && fe.id !== financialElement.id)
        .map(fe => ({ value: fe.id, alias: fe.title }));

    return (
        <main>
            <button className='button-aux w-fit p-1 col-span-full' onClick={() => navigate(`/debts`)}>
                <svg xmlns="http://www.w3.org/2000/svg" className="mr-2 mt-0.5 h-2.5 w-2.5" viewBox="0 0 24 24"><path fill="currentColor" d="m3.55 12l7.35 7.35q.375.375.363.875t-.388.875q-.375.375-.875.375t-.875-.375l-7.7-7.675q-.3-.3-.45-.675T.825 12q0-.375.15-.75t.45-.675l7.7-7.7q.375-.375.888-.363t.887.388q.375.375.375.875t-.375.875L3.55 12Z" /></svg>
                <div>Volver al listado</div>
            </button>
            <div className='flex flex-col space-y-3 lg:space-y-8'>
                <article className='bg-sf-red-light h-fit'>
                    <h2>Editar deuda</h2>
                    <div className='border-t border-black w-full h-min pb-1'></div>
                    <input name='title' type='text' placeholder='Título *' value={financialElement.title} onChange={handleFinancialElementInputChange} className='field' />
                    <input name='description' type='text' placeholder='Descripción (opcional)' value={financialElement.description} onChange={handleFinancialElementInputChange} className='field' />

                    {(financialElement.interest_rate || financialElement.minimum_payment || financialElement.priority || financialElement.transactional || financialElement.deferred || !financialElements.find(fe => fe.deferred === debt_id)) &&
                        <div className='space-y-3'>
                            <div className="pr-1 w-fit flex items-center space-x-2 m-2 cursor-pointer" onClick={() => setFinancialElement(oldFinancialElement => ({ ...oldFinancialElement, transactional: !oldFinancialElement.transactional }))}>
                                <p>¿Es de uso cotidiano?</p>
                                <div className="h-4 w-4 rounded-sm bg-sf-white flex justify-center items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" className={`w-full text-sf-violet-dark ${financialElement.transactional ? '' : 'hidden'}`} viewBox="0 0 24 24"><path fill="currentColor" d="m10 13.6l5.9-5.9q.275-.275.7-.275t.7.275q.275.275.275.7t-.275.7l-6.6 6.6q-.3.3-.7.3t-.7-.3l-2.6-2.6q-.275-.275-.275-.7t.275-.7q.275-.275.7-.275t.7.275l1.9 1.9Z" /></svg>
                                </div>
                            </div>
                            <div className="pr-1 w-fit flex items-center space-x-2 m-2 cursor-pointer" onClick={() => setDeferEnable(oldDeferEnable => !oldDeferEnable)}>
                                <p>¿Permite realizar operaciones en cuotas?</p>
                                <div className="h-4 w-4 rounded-sm bg-sf-white flex justify-center items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" className={`w-full text-sf-violet-dark ${deferEnable ? '' : 'hidden'}`} viewBox="0 0 24 24"><path fill="currentColor" d="m10 13.6l5.9-5.9q.275-.275.7-.275t.7.275q.275.275.275.7t-.275.7l-6.6 6.6q-.3.3-.7.3t-.7-.3l-2.6-2.6q-.275-.275-.275-.7t.275-.7q.275-.275.7-.275t.7.275l1.9 1.9Z" /></svg>
                                </div>
                            </div>
                            {deferEnable &&
                                <div className='flex flex-col space-y-3 px-3'>
                                    {!currentlyHasDeferAccount &&
                                        <div className='field p-2 w-full space-x-0.5 flex justify-evenly'>
                                            <div className='flex items-center space-x-1 cursor-pointer hover:bg-sf-gray-extra-light rounded-lg p-1' onClick={() => setCreateDeferAccount(true)}>
                                                <div className={`rounded-full h-3.5 w-3.5 ${createDeferAccount ? 'bg-sf-violet-dark' : 'ring-1 ring-inset ring-sf-violet-dark'}`}></div>
                                                <div className='whitespace-nowrap'>Crear cuenta</div>
                                            </div>
                                            <div className='flex items-center space-x-1 cursor-pointer hover:bg-sf-gray-extra-light rounded-lg p-1' onClick={() => setCreateDeferAccount(false)}>
                                                <div className={`rounded-full h-3.5 w-3.5 ${!createDeferAccount ? 'bg-sf-violet-dark' : 'ring-1 ring-inset ring-sf-violet-dark'}`}></div>
                                                <div className='whitespace-nowrap'>Seleccionar cuenta</div>
                                            </div>
                                        </div>
                                    }
                                    {createDeferAccount && !currentlyHasDeferAccount ?
                                        <p className='field'>{financialElement.title} (cuotas pendientes)</p>
                                        :
                                        <Select placeholder='Cuenta para cuotas pendientes' name='deferred' value={financialElement.deferred} onChange={handleFinancialElementInputChange} options={financialElementsOptions} />
                                    }
                                </div>
                            }
                        </div>
                    }

                    <div className='self-end flex space-x-2 w-full'>
                        <div className='w-full min-h-full'>
                            {financialElement.status === 'ENABLED' ?
                                <button className="h-full button-secondary-red" onClick={() => setConfirmDialog(confirmDisableDialogParams)}>
                                    Deshabilitar
                                </button>
                                :
                                <button className="h-full button-secondary" onClick={() => setConfirmDialog(confirmEnableDialogParams)}>
                                    Habilitar
                                </button>
                            }
                        </div>
                        <button className="button-primary" disabled={!financialElement.title || !financialElement.status || financialElement.transactional === undefined || (deferEnable && (financialElement.deferred ?? '') === '' && !createDeferAccount)} onClick={saveUpdateFinancialElementChanges}>
                            Guardar
                        </button>
                    </div>
                </article>
                <article className="p-0 space-y-0 h-fit">
                    <div className="flex w-full flex-row justify-between items-center p-3">
                        <h2>Registros de saldo</h2>
                        <button className="button-aux flex space-x-1 items-center" onClick={() => { setDisplayNewValuation(true); setSelectedNewType(FinancialElementTypes.DEBT); setDisplayValuationUpdate(false); }}>
                            <p>Nuevo</p>
                            <svg xmlns="http://www.w3.org/2000/svg" className="ml-2 h-3.5" viewBox="0 0 24 24"><path fill="currentColor" d="M11 13H6q-.425 0-.713-.288T5 12q0-.425.288-.713T6 11h5V6q0-.425.288-.713T12 5q.425 0 .713.288T13 6v5h5q.425 0 .713.288T19 12q0 .425-.288.713T18 13h-5v5q0 .425-.288.713T12 19q-.425 0-.713-.288T11 18v-5Z" /></svg>
                        </button>
                    </div>
                    {displayNewValuation &&
                        <div className='p-4 pt-0'>
                            <article className='bg-sf-red-light'>
                                <h2>Nuevo registro de saldo</h2>
                                <div className='field p-2 w-full justify-evenly'>
                                    {[FinancialElementTypes.ASSET, FinancialElementTypes.DEBT].map(type => (
                                        <div key={type.key} className='flex items-center space-x-1 cursor-pointer hover:bg-sf-gray-extra-light rounded-lg p-1' onClick={() => setSelectedNewType(type)}>
                                            <div className={`rounded-full h-3.5 w-3.5 ${type.key === selectedNewType?.key ? 'bg-sf-violet-dark' : 'ring-1 ring-inset ring-sf-violet-dark'}`}></div>
                                            <div>{type.label}</div>
                                        </div>
                                    ))}
                                </div>
                                <input name='timestamp' type='date' value={newValuation.timestamp} onChange={ifDateIsValidThen(handleNewValuationInputChange)} className='field' />
                                <div className='flex space-x-3'>
                                    <div className='w-48'>
                                        <Select placeholder='Moneda' name='currency_code' value={newValuation.currency_code} onChange={handleNewValuationInputChange} options={client.currencies?.map(currency => ({ value: currency.code, alias: getSign(currency.code) }))} />
                                    </div>
                                    <input type='text' inputMode='decimal' name='amount' placeholder='Saldo *' value={newValuation.amount} onChange={e => processDecimalInput(e) && handleNewValuationInputChange(e)} className='field' />
                                </div>
                                <div className='flex w-full justify-between items-center'>
                                    <button className='button-secondary' onClick={() => setDisplayNewValuation(false)}>Cerrar</button>
                                    <button className='button-primary my-0' disabled={!newValuation.timestamp || !newValuation.currency_code || !newValuation.amount} onClick={saveCreateNewValuation}>Guardar</button>
                                </div>
                            </article>
                        </div>
                    }
                    {displayValuationUpdate &&
                        <div className='p-4 pt-0'>
                            <article className='bg-sf-red-light'>
                                <div className='flex justify-between items-center'>
                                    <h2>Editar registro de saldo</h2>
                                    {valuationToUpdate.auto_generated && <p className='secondary'>(autogenerado)</p>}
                                </div>
                                <div className='field p-2 w-full justify-evenly'>
                                    {[FinancialElementTypes.ASSET, FinancialElementTypes.DEBT].map(type => (
                                        <div key={type.key} className='flex items-center space-x-1 cursor-pointer hover:bg-sf-gray-extra-light rounded-lg p-1' onClick={() => setSelectedUpdateType(type)}>
                                            <div className={`rounded-full h-3.5 w-3.5 ${type.key === selectedUpdateType?.key ? 'bg-sf-violet-dark' : 'ring-1 ring-inset ring-sf-violet-dark'}`}></div>
                                            <div>{type.label}</div>
                                        </div>
                                    ))}
                                </div>
                                <input name='timestamp' type='date' value={valuationToUpdate.timestamp} onChange={ifDateIsValidThen(handleValuationToUpdateInputChange)} className='field' />
                                <div className='flex space-x-3'>
                                    <div className='w-48'>
                                        <Select placeholder='Moneda' name='currency_code' value={valuationToUpdate.currency_code} onChange={handleValuationToUpdateInputChange} options={updateCurrencyCodes.map(currencyCode => ({ value: currencyCode, alias: getSign(currencyCode) }))} />
                                    </div>
                                    <input type='text' inputMode='decimal' name='amount' placeholder='Saldo *' value={valuationToUpdate.amount} onChange={e => processDecimalInput(e) && handleValuationToUpdateInputChange(e)} className='field' />
                                </div>
                                <div className='flex w-full justify-between items-center'>
                                    <button className='button-secondary' onClick={() => setDisplayValuationUpdate(false)}>Cerrar</button>
                                    <div className='flex w-fit space-x-3'>
                                        <button className='button-secondary-red' onClick={() => setConfirmDialog(confirmDeleteValuationDialogParams)}>Eliminar</button>
                                        <button className='button-primary my-0' disabled={!valuationToUpdate.timestamp || !valuationToUpdate.currency_code || !valuationToUpdate.amount} onClick={saveUpdateValuation}>Guardar</button>
                                    </div>
                                </div>
                            </article>
                        </div>
                    }
                    <p className='px-4 pb-3'>Más reciente primero</p>
                    <div onScroll={onScrollHandler} ref={ref} className={`px-4 max-h-96 rounded-b-2xl overflow-auto ${boxShadow}`}>
                        <ul className="divide-y divide-sf-black border-t border-sf-black">
                            {valuations.length > 0
                                ? valuations.map((valuation: Valuation) => <div key={valuation.id} className="flex w-full justify-between space-x-2 px-1 py-3 hover:bg-sf-gray-extra-light cursor-pointer" onClick={() => handleEditValuation(valuation)}>
                                    <div className='flex space-x-1 items-center'>
                                        <p>{new Date(parseInt(valuation.timestamp ?? '')).toLocaleDateString('es', { timeZone: 'UTC' })}</p>
                                        {valuation.auto_generated && <p className='secondary'>(autogenerado)</p>}
                                    </div>
                                    <div className={`flex ${valuation.amount?.includes('-') ? 'expenditure' : 'income'} `}>
                                        <p className="font-mono">{valuation.amount?.includes('-') ? '-' : '+'}</p>
                                        <p className="mx-1">{getSign(valuation.currency_code)}</p>
                                        <p>{getInteger(valuation.amount ?? '')}</p>
                                        <p className="text-[11px]/[16px] ml-[1px]">{getDecimal(valuation.amount ?? '')}</p>
                                    </div>
                                </div>)
                                : <EmptyList message="No hay saldos registrados." />
                            }
                        </ul>
                    </div>
                </article>
            </div>
            <OperationsAndValuations financialElement={financialElement} valuations={valuations} setValuations={setValuations} />
        </main>
    );
}
