import Box from '@mui/joy/Box';
import { useNavigate } from 'react-router-dom';
import SomosfinLogo from '../../components/initial-profile/brand-images/SomosfinLogo';
import Typography from '@mui/joy/Typography';
import Button from '@mui/joy/Button';
import SafeAreaInsetBottom from '../../components/initial-profile/SafeAreaInsetBottom';
import SafeAreaInsetTop from '../../components/initial-profile/SafeAreaInsetTop';
import DesktopHeader from '../../components/initial-profile/DesktopHeader';
import Shapes from '../../components/initial-profile/brand-images/Shapes';
import Chip from '@mui/joy/Chip';
import Alert from '@mui/joy/Alert';
import { useCallback, useContext, useEffect, useState } from 'react';
import FormLayout from './FormLayout';
import CustomDatePicker from '../../components/initial-profile/CustomDatePicker';
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { DigitalClock } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import { Context } from '../../utils/context';
import Loading from '../../components/Loading';
import { useAPI } from '../../hooks/UseAPI';
import TextInput from '../../components/initial-profile/TextInput';
import Textarea from '@mui/joy/Textarea';

export default function Summary(): JSX.Element {
    const { client, setClient } = useContext(Context);
    const { listClientAppointments, createClientAppointment, listAvailableTimeSlots, updateClient } = useAPI();
    const navigate = useNavigate();
    const [isScheduleFormOpen, setIsScheduleFormOpen] = useState(false);
    const [isConfirmFormOpen, setIsConfirmFormOpen] = useState(false);
    const [skipMeetingSchedule, setSkipMeetingSchedule] = useState(false);
    const [scheduledMeeting, setScheduledMeeting] = useState<Dayjs | null>(null);
    const [selectedDate, setSelectedDate] = useState<Dayjs | null>(null);
    const [selectedTime, setSelectedTime] = useState<Dayjs | null>(null);
    const [clientName, setClientName] = useState<string>('');
    const [clientDescription, setClientDescription] = useState<string>('');
    const [clientPhoneNumber, setClientPhoneNumber] = useState<string>('');
    const [availableTimes, setAvailableTimes] = useState<number[]>([]);
    const [slotDurationMinutes, setSlotDurationMinutes] = useState<number>(0);
    const [loading, setLoading] = useState(true);

    const setUp = useCallback(async () => {
        try {
            setLoading(true);
            const retrievedScheduledMeeting = await listClientAppointments(client.id ?? '');
            const retrievedScheduledMeetingDayJs = retrievedScheduledMeeting.timestamps.length > 0 ? dayjs.unix(parseInt(retrievedScheduledMeeting.timestamps[0]) / 1000) : null;
            if (retrievedScheduledMeetingDayJs === null) {
                const retrievedAvailableTimes = await listAvailableTimeSlots();
                setAvailableTimes(retrievedAvailableTimes.timestamps.map(slotTime => parseInt(slotTime)));
            }
            setIsScheduleFormOpen(false);
            setIsConfirmFormOpen(false);
            setScheduledMeeting(retrievedScheduledMeetingDayJs);
            setSelectedDate(null);
            setSelectedTime(null);
            setClientName('');
            setClientDescription('');
            setClientPhoneNumber('');
            setSlotDurationMinutes(parseInt(retrievedScheduledMeeting.duration_minutes));
        } finally {
            setLoading(false);
        }
    }, [client.id, listClientAppointments, listAvailableTimeSlots]);

    useEffect(() => { setUp() }, [setUp]);

    useEffect(() => {
        if ((selectedDate && selectedTime) && !availableTimes.some(availableTime => availableTime === combineDateAndTime(selectedDate, selectedTime).valueOf())) {
            setSelectedTime(null);
        }
    }, [selectedDate, selectedTime, availableTimes]);

    const minDate = dayjs.unix(Math.min(...availableTimes) / 1000);
    const maxDate = dayjs.unix(Math.max(...availableTimes) / 1000);
    const isFormButtonDisabled = loading || !selectedDate || !selectedTime || clientName.length < 3;

    function getFormattedDate(date: Dayjs | null): string {
        if (!date) return '';
        const cap = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

        return `${cap(date.format('dddd'))}, ${date.format('DD')} de ${cap(date.format('MMMM'))}`;
    }

    function getFormattedTime(date: Dayjs | null): string {
        if (!date) return '';

        return `De ${date.format('HH:mm')} a ${date.add(slotDurationMinutes, 'minute').format('HH:mm')}`;
    }

    function combineDateAndTime(date: Dayjs, time: Dayjs): Dayjs {
        return date.hour(time.hour()).minute(time.minute());
    }

    function shouldDisableDate(date: Dayjs) {
        const startOfDay = date.startOf('day').valueOf();
        const endOfDay = date.endOf('day').valueOf();

        return !availableTimes.some(availableTime => startOfDay <= availableTime && availableTime <= endOfDay);
    }

    function shouldDisableTime(time: Dayjs) {
        if (selectedDate === null) {
            return true;
        }

        const timeToEval = combineDateAndTime(selectedDate, time).valueOf();

        return !availableTimes.some(availableTime => availableTime === timeToEval);
    }

    async function submitForm() {
        if (isFormButtonDisabled) {
            return;
        }
        try {
            setLoading(true);
            const timestamp = combineDateAndTime(selectedDate, selectedTime);
            const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

            const clientToupdate = {
                ...client,
                name: clientName,
                description: clientDescription,
                phone_number: clientPhoneNumber,
                timezone: timezone
            };
            const updateClientPromise = updateClient(client.id ?? '', clientToupdate)
                .then(() => setClient(clientToupdate));

            const createClientAppointmentPromise = createClientAppointment(client.id ?? '', {
                timestamp: timestamp.valueOf().toString(),
                client_name: clientName,
                client_description: clientDescription,
                client_phone_number: clientPhoneNumber,
                timezone: timezone
            });

            await Promise.all([updateClientPromise, createClientAppointmentPromise]);
        } finally {
            await setUp();
            setLoading(false);
        }
    }

    function getMeetingSummary(date: Dayjs) {
        return (
            <Box sx={theme => ({
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                borderWidth: '1px',
                borderColor: theme.vars.palette.neutral[400],
                p: 2,
                borderRadius: '10px',
                width: '100%',
            })}>
                <Box>
                    <Typography>Primer encuentro con tu Coach</Typography>
                    <Typography level='title-md' fontWeight='lg' fontFamily='ESRebondGrotesque Medium'>
                        {getFormattedDate(date)}
                    </Typography>
                </Box>
                <Chip startDecorator={
                    <AccessTimeRoundedIcon sx={{
                        width: '16px',
                        height: '16px',
                        color: 'currentColor'
                    }} />
                } sx={theme => ({
                    backgroundColor: theme.vars.palette.primary.plainHoverBg
                })}>{getFormattedTime(date)}</Chip>
                <Typography level='body-sm'>
                    Los detalles de la reunión llegarán a:<br /><b>{client.email}</b>
                </Typography>
            </Box >
        );
    }

    return (<>
        <Box sx={{
            position: 'fixed',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            width: '100vw',
            height: '100vh',
            backgroundColor: '#7756E2',
        }}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'start',
                alignItems: 'center',
                height: { xs: '100%', md: '370px' },
                minHeight: { xs: 'auto', md: '370px' },
                width: '100%',
            }}>
                <DesktopHeader />
                <SafeAreaInsetTop minValue='16px' />
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-evenly',
                    alignItems: { xs: 'center', md: 'start' },
                    width: '100%',
                    height: '100%',
                    fill: 'white',
                    maxWidth: '500px',
                    pb: 2,
                }}>
                    <Box sx={{
                        width: '100%',
                        maxWidth: '140px',
                        display: { xs: undefined, md: 'none' },
                    }}>
                        <SomosfinLogo />
                    </Box>
                    <Box sx={{
                        p: 2,
                        width: '100%',
                        display: { xs: undefined, md: 'none' },
                        maxWidth: { xs: '235px', sm: '370px' },
                    }}>
                        <Shapes />
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: { xs: 'center', md: 'start' },
                        gap: { xs: 2, md: 3 },
                        maxWidth: '325px',
                        textAlign: { xs: 'center', md: 'start' },
                    }}>
                        <Typography level='h3' fontFamily='ESRebondGrotesque Medium' sx={{
                            color: 'white',
                        }}>Ya tienes tu perfil inicial</Typography>
                        <Typography sx={{
                            color: 'white',
                            fontSize: { xs: '16px', md: '18px' }
                        }}>Junto a tu coach lo utilizarán para alcanzar tus objetivos desde tu próximo encuentro.</Typography>
                    </Box>
                    <Box sx={{
                        position: 'fixed',
                        height: '445px',
                        top: '-75px',
                        right: '-50px',
                        display: { xs: 'none', md: 'flex' },
                        flexDirection: 'column',
                        overflow: 'hidden'
                    }}>
                        <SafeAreaInsetTop minValue='16px' />
                        <Box sx={{
                            width: '480px',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                            <Shapes />
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
                backgroundColor: 'white',
                borderTopRightRadius: '10px',
                borderTopLeftRadius: '10px',
                height: { xs: 'auto', md: '100%' },
            }}>
                <Box sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'start',
                    alignItems: 'center',
                    pt: 2,
                    px: 2,
                    width: '100%',
                    maxWidth: '532px',
                }}>
                    {loading ?
                        <Box>
                            <Box sx={{
                                minHeight: '226px',
                            }}>
                                <Loading />
                            </Box>
                            <SafeAreaInsetBottom minValue='16px' handleKeyboard={false} />
                        </Box>
                        :
                        scheduledMeeting ?
                            getMeetingSummary(scheduledMeeting)
                            :
                            <Box sx={theme => ({
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 2,
                                borderWidth: '1px',
                                borderColor: theme.vars.palette.neutral[400],
                                p: 2,
                                borderRadius: '10px',
                                width: '100%',
                            })}>
                                {skipMeetingSchedule ?
                                    <Box sx={{
                                        textAlign: 'center'
                                    }}>
                                        <Typography level='title-md' fontWeight='lg' fontFamily='ESRebondGrotesque Medium'>Tu coach se pondrá en contacto</Typography>
                                    </Box>
                                    :
                                    <>
                                        <Box>
                                            <Typography level='title-md' fontWeight='lg' fontFamily='ESRebondGrotesque Medium'>Agenda un encuentro con un coach</Typography>
                                            <Typography>Para acompañarte a poner en orden tus finanzas y alcanzar tus objetivos</Typography>
                                        </Box>
                                        <Box sx={{
                                            width: '100%',
                                            display: 'flex',
                                            justifyContent: 'center'
                                        }}>
                                            <Button size='lg' fullWidth onClick={() => setIsScheduleFormOpen(true)}>
                                                Agendar gratis
                                            </Button>
                                        </Box>
                                    </>
                                }
                            </Box>
                    }
                </Box>
                <Box sx={{
                    width: '100%',
                    flexDirection: 'column',
                    alignItems: 'center',
                    height: 'fit-content',
                    display: loading ? 'none' : 'flex',
                }}>
                    <Box sx={{
                        pt: 2,
                        px: 2,
                        width: '100%',
                        maxWidth: '532px',
                        display: (scheduledMeeting || skipMeetingSchedule) ? 'auto' : 'none'
                    }}>
                        <Button size='lg' variant='plain' fullWidth onClick={() => navigate('/1')}>
                            Revisar perfil
                        </Button>
                    </Box>
                    <Box sx={{
                        pt: 2,
                        px: 2,
                        width: '100%',
                        maxWidth: '532px',
                        display: (scheduledMeeting || skipMeetingSchedule) ? 'none' : 'auto'
                    }}>
                        <Button size='lg' variant='plain' fullWidth onClick={() => setSkipMeetingSchedule(true)}>
                            Ya estoy suscripto
                        </Button>
                    </Box>
                    <SafeAreaInsetBottom minValue='16px' handleKeyboard={false} />
                </Box>
            </Box>
        </Box>
        <FormLayout
            goBack={() => setIsScheduleFormOpen(false)}
            submit={() => setIsConfirmFormOpen(true)}
            title='Agenda un encuentro'
            isButtonDisabled={isFormButtonDisabled}
            visible={isScheduleFormOpen}
            buttonText='Siguiente'
        >
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                minHeight: '100%',
            }}>
                <Box sx={{
                    gap: { xs: 3, md: 6 },
                    pb: { xs: 3, md: 6 },
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    minHeight: 'fit-content',
                }}>
                    <Box sx={{
                        gap: 0.75,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: 'fit-content',
                    }}>
                        <Typography level='title-sm'>
                            Selecciona una fecha
                        </Typography>
                        <CustomDatePicker value={selectedDate} setValue={setSelectedDate} min={minDate} max={maxDate} shouldDisable={shouldDisableDate} />
                    </Box>
                    <Box sx={{
                        gap: 0.75,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: 'fit-content',
                        visibility: selectedDate ? 'visible' : 'hidden'
                    }}>
                        <Typography level='title-sm'>
                            Selecciona un horario
                        </Typography>
                        <DigitalClock
                            value={selectedTime}
                            onChange={setSelectedTime}
                            shouldDisableTime={shouldDisableTime}
                            minutesStep={slotDurationMinutes}
                            skipDisabled
                            sx={{
                                boxShadow: '0px 1px 2px 0px #15151514',
                                backgroundColor: '#FBFCFE',
                                border: '1px solid #CDD7E1',
                                borderRadius: '6px',
                                'li': {
                                    borderRadius: '6px',
                                    m: '6px',
                                    p: '6px',
                                    minHeight: '36px',
                                    justifyContent: 'center'
                                },
                                maxHeight: '150px',
                                height: '100%',
                                width: '156px',
                                '@media (pointer: fine)': {
                                    width: '192px',
                                },
                            }}
                        />
                    </Box>
                    <Box sx={{
                        gap: 0.75,
                        display: 'flex',
                        flexDirection: 'column',
                        textAlign: 'center',
                        visibility: selectedDate ? 'visible' : 'hidden',
                        width: '156px',
                        '@media (pointer: fine)': {
                            width: '192px',
                        },
                        'input': {
                            textAlign: 'center'
                        }
                    }}>
                        <Typography level='title-sm'>
                            ¿Cuál es tu nombre?
                        </Typography>
                        <TextInput
                            value={clientName}
                            setValue={setClientName}
                        />
                    </Box>
                </Box>
                <Alert variant='soft' startDecorator={
                    <InfoOutlinedIcon sx={{
                        width: '16px',
                        height: '16px',
                    }} />
                }>
                    Agendar un encuentro es completamente gratuito. No te pediremos un medio de pago ni datos de tu tarjeta de crédito.
                </Alert>
            </Box>
        </FormLayout>
        <FormLayout
            goBack={() => setIsConfirmFormOpen(false)}
            submit={submitForm}
            title='Confirmar reunión'
            isButtonDisabled={isFormButtonDisabled}
            visible={isConfirmFormOpen}
            buttonText='Confirmar reunión'
        >
            {(selectedDate && selectedTime) && getMeetingSummary(combineDateAndTime(selectedDate, selectedTime))}
            <Box sx={{
                pt: 2,
                mt: 2,
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
            }}>
                <Typography level='title-sm' fontWeight='lg'>
                    Información adicional
                </Typography>
                <Box sx={{
                    gap: 0.75,
                    display: 'flex',
                    flexDirection: 'column',
                }}>
                    <Typography level='title-sm'>
                        Número de teléfono (opcional)
                    </Typography>
                    <Typography level='body-xs'>
                        Para enviarte un recordatorio
                    </Typography>
                    <TextInput
                        value={clientPhoneNumber}
                        setValue={setClientPhoneNumber}
                    />
                </Box>
                <Box sx={{
                    gap: 0.75,
                    display: 'flex',
                    flexDirection: 'column',
                }}>
                    <Typography level='title-sm'>
                        ¿Algo más que quieras que sepamos? (opcional)
                    </Typography>
                    <Textarea
                        minRows={3}
                        value={clientDescription}
                        onChange={e => setClientDescription(e.target.value)}
                        sx={{
                            'textarea:focus': { boxShadow: 'none' }
                        }}
                    />
                </Box>
            </Box>
        </FormLayout >
    </>);
}
