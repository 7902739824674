import AsyncLock from "async-lock";
import { Operation } from "../model/Operation";
import { isValidTimestamp } from "./date-utils";
import { isValidUUID } from "./string-utils";

const MAX_ITERATIONS = 40;
const SEPARATOR = '#';
const ASYNC_LOCK_KEY = 'OperationsService.cache';

class OperationsService {

    private subscribers: Set<() => void> = new Set();

    private cache: Map<string, Operation[]> = new Map();

    private asyncLock: AsyncLock = new AsyncLock();

    private notifySubscribers = () => {
        this.subscribers.forEach(callback => {
            // Wrap with setTimeout(() => { ... }, 0); to execute all callbacks in a non-blocking way.
            try {
                // console.log('Notifying subscriber');
                callback();
            } catch (error) {
                // console.error('Error in subscriber callback:', error);
            }
        });
    };

    private mergeOperations = (operations1: Operation[], operations2: Operation[]): Operation[] => {
        const uniqueOperations = new Map<string, Operation>();
        [...operations1, ...operations2].forEach(op => {
            if (op.id !== undefined) {
                uniqueOperations.set(op.id, op);
            }
        });
        return Array.from(uniqueOperations.values()).sort((op1, op2) => (op1.timestamp ?? "").localeCompare(op2.timestamp ?? ""));
    };

    private consolidateCache = () => {
        let cacheModified = true;

        for (let i = 0; cacheModified && (i <= MAX_ITERATIONS); i++) {
            if (i === MAX_ITERATIONS) {
                console.error('MAX_ITERATIONS reached in consolidateCache');
            }
            cacheModified = false;
            const consolidatedEntries: Map<string, Operation[]> = new Map();

            for (const [key, operations] of Array.from(this.cache)) {
                const [start, end] = key.split(SEPARATOR);
                let merged = false;

                for (const [consolidatedKey, consolidatedOperations] of Array.from(consolidatedEntries)) {
                    const [consolidatedStart, consolidatedEnd] = consolidatedKey.split(SEPARATOR);

                    const isOverlap = consolidatedStart < end && consolidatedEnd > start;
                    const isAdjacent = consolidatedStart === end || consolidatedEnd === start;

                    if (isOverlap || isAdjacent) {
                        const newStart = start < consolidatedStart ? start : consolidatedStart;
                        const newEnd = end > consolidatedEnd ? end : consolidatedEnd;
                        const mergedOperations = this.mergeOperations(consolidatedOperations, operations);

                        consolidatedEntries.delete(consolidatedKey);
                        consolidatedEntries.set(`${newStart}${SEPARATOR}${newEnd}`, mergedOperations);

                        cacheModified = true;
                        merged = true;
                        break;
                    }
                }

                if (!merged) {
                    consolidatedEntries.set(key, operations);
                }
            }

            this.cache = consolidatedEntries;
        }
    };

    listOperationsByRange = (clientId: string, from: string, to: string, listOperations: (clientId: string, filters: any) => Promise<Operation[]>): Promise<Operation[]> => this.asyncLock.acquire(ASYNC_LOCK_KEY, async () => {
        if (!isValidUUID(clientId) || !isValidTimestamp(from) || !isValidTimestamp(to) || from > to) {
            console.error('Invalid parameters', clientId, from, to);
            throw new Error('Invalid parameters');
        }
        // console.log(`List operations by range\n    from: ${new Date(parseInt(from)).toISOString().substring(0, 10)}\n    to: ${new Date(parseInt(to)).toISOString().substring(0, 10)}`);

        const cacheKey = `${from}${SEPARATOR}${to}`;
        if (this.cache.has(cacheKey)) {
            return this.cache.get(cacheKey) ?? [];
        }

        let cachedOperations: Operation[] = [];
        let fetchRanges: { start: string, end: string }[] = [{ start: from, end: to }];

        for (const [key, operations] of Array.from(this.cache)) {
            const [cachedStart, cachedEnd] = key.split(SEPARATOR);
            let trimmedRange = true;

            for (let i = 0; trimmedRange && (i <= MAX_ITERATIONS); i++) {
                if (i === MAX_ITERATIONS) {
                    console.error('MAX_ITERATIONS reached in listOperationsByRange');
                }
                trimmedRange = false;
                for (const { start, end } of fetchRanges) {
                    const isOverlap = cachedStart < end && cachedEnd > start;
                    const rangeIsPointLike = start === end;
                    const isAdjacent = cachedStart === end || cachedEnd === start;

                    if (isOverlap || (rangeIsPointLike && isAdjacent)) {
                        cachedOperations = this.mergeOperations(cachedOperations, operations);
                        fetchRanges = fetchRanges.filter(range => !(range.end === end && range.start === start));
                        if (start < cachedStart) {
                            fetchRanges.push({ start: start, end: cachedStart });
                        }
                        if (cachedEnd < end) {
                            fetchRanges.push({ start: cachedEnd, end: end });
                        }
                        trimmedRange = true;
                    }
                }
            }
        }

        const newOperations = await Promise.all(fetchRanges.map(range => {
            console.warn(`LIST OPERATIONS\n    from: ${new Date(parseInt(range.start)).toISOString().substring(0, 10)}\n    to: ${new Date(parseInt(range.end)).toISOString().substring(0, 10)}`);
            return listOperations(clientId, { from: range.start, to: range.end });
        }));

        cachedOperations = this.mergeOperations(cachedOperations, newOperations.flat()).filter(op => op.timestamp !== undefined && op.timestamp >= from && op.timestamp <= to);
        this.cache.set(cacheKey, cachedOperations);
        this.consolidateCache();

        return cachedOperations;
    });

    createOperation = (clientId: string, newOperation: Operation, createOperation: (clientId: string, newOperation: Operation) => Promise<Operation>): Promise<Operation> => this.asyncLock.acquire(ASYNC_LOCK_KEY, () => {
        try {
            return createOperation(clientId, newOperation);
        } finally {
            this.clearCacheAndNotifySubscribers();
        }
    });

    createOperationBatch = (clientId: string, newOperations: Operation[], createOperationBatch: (clientId: string, newOperations: Operation[]) => Promise<Operation[]>): Promise<Operation[]> => this.asyncLock.acquire(ASYNC_LOCK_KEY, () => {
        try {
            return createOperationBatch(clientId, newOperations);
        } finally {
            this.clearCacheAndNotifySubscribers();
        }
    });

    updateOperation = (clientId: string, id: string, newOperation: Operation, updateOperation: (clientId: string, id: string, newOperation: Operation) => Promise<void>): Promise<void> => this.asyncLock.acquire(ASYNC_LOCK_KEY, () => {
        try {
            return updateOperation(clientId, id, newOperation);
        } finally {
            this.clearCacheAndNotifySubscribers();
        }
    });

    deleteOperation = (clientId: string, id: string, deleteOperation: (clientId: string, id: string) => Promise<void>): Promise<void> => this.asyncLock.acquire(ASYNC_LOCK_KEY, () => {
        try {
            return deleteOperation(clientId, id);
        } finally {
            this.clearCacheAndNotifySubscribers();
        }
    });

    deleteOperationBatch = (clientId: string, operationsIds: string[], deleteOperationBatch: (clientId: string, operationsIds: string[]) => Promise<void>): Promise<void> => this.asyncLock.acquire(ASYNC_LOCK_KEY, () => {
        try {
            return deleteOperationBatch(clientId, operationsIds);
        } finally {
            this.clearCacheAndNotifySubscribers();
        }
    });

    subscribe = (callback: () => void): (() => void) => {
        this.subscribers.add(callback);
        return () => this.subscribers.delete(callback);
    };

    clearCacheAndNotifySubscribers = () => {
        this.cache.clear();
        this.notifySubscribers();
    };
}

const operationsService = new OperationsService();
export default operationsService;
